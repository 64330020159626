.ResumeEmbed {
    position: relative;
    height: 0;
    padding-bottom: 118.5%;

}

.ResumeEmbed iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}