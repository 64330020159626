.sidebar-mt{
  margin-top: 20px;
}
@media (min-width: 1200px){
  .sidebar-mt{
    margin-top: 30px;
  }
}

.resume-page .uk-card-header .uk-icon-button{
    background-color: var(--greenish);
    color: white;
}
.resume-page .uk-card-header .uk-icon-button:hover{
    background-color: var(--blue-lt);
    color: white;
}